header {
	width: fit-content;
	margin: 0 auto;
	padding: 20px min(5rem, 15vw);
    background-color: #fff;
	border: 2px solid #fff;
	border-radius: 0 0 8px 8px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	text-align: center;
	font-size: 14px;
	outline: none;
	text-decoration: none;
	transition: all 0.8s;
}

header h1 {
	font-size: 1.1em;
	margin: 0;
}

header p {
	font-size: 0.9em;
	margin: 0;
}

#map {
	position: fixed;
	top: 0;
	left: 0;
	padding: 0;
	margin: 0;
	z-index: -1;
}

#sourceButton {
	position: fixed;
	bottom: 10px;
	left: 10px;
	z-index: 1;

	background-color: #fff;
	border: 2px solid #fff;
	border-radius: 8px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	cursor: pointer;
	margin-bottom: 12px;
	text-align: center;
	font-size: 14px;
	outline: none;
	padding: 10px 15px;
	text-decoration: none;
}

#sourceButton:hover {
	background-color: #ebebeb;
	border-color: #ebebeb;
}

#sourceButton:focus {
	outline: 2px solid #000;
}

#sources {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 10;
	transform: translate(-50%, -50%);
	overflow-y: scroll;
	width: min(700px, 70vw);
	height: min(500px, 80vh);
	padding: 1rem 2.5rem;
	background-color: rgba(255, 255, 255, 0.9);
    border: 2px solid #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	border-radius: 25px;
	text-align: left;
}

#sources h3 {
	font-size: 1.6rem;
}

#sources button {
	position: absolute;
	top: 2rem;
	right: 2rem;
	padding: 0.5rem;
	cursor: pointer;
	background-color: transparent;
	border: none;
	font-size: 1.8rem;
	transition: all 0.8s;
}

#source button:hover, #source button:active {
	background-color: #ebebeb;
	border-color: #ebebeb;
}

#legend {
	position: fixed;
	bottom: 10px;
	right: 10px;
	height: 35vh;
	z-index: 1;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

	background-color: #fff;
	border: 2px solid #fff;
	border-radius: 8px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	margin-bottom: 12px;
	text-align: center;
	font-size: 0.8rem;
	outline: none;
	padding: 10px 15px;
	text-decoration: none;
	transition: all 0.8s;
}

#legend:hover,
#legend:active {
	background-color: #ebebeb;
	border-color: #ebebeb;
}

#legend hr {
	grid-column: 1;
	grid-row: 1/6;

	border: 1px solid rgb(216, 216, 216);
	margin: 2px;
	padding: 10px;
	border-radius: 3px;
	background: linear-gradient(to bottom, #a00a0a, #ff9650, #dfdfdf);
}

#legend span {
	grid-column: 2;
	align-self: center;
}

#legend p {
	grid-column: 3;
	grid-row: 1/6;
	align-self: center;
	writing-mode: tb-rl;
	transform: rotate(180deg);
	padding: 0;
	margin: 0;
	margin-left: 5px;
}

#banner {
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    margin: 0;
    padding: 5px 0px;
    z-index: 1;
    background-color: yellow;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    color: #000;
}

#banner p {
    margin: 0;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

#banner button {
	top: 2rem;
	right: 2rem;
	padding: 0 1.5rem;
	cursor: pointer;
	background-color: transparent;
	border: none;
	font-size: 1.4rem;
}

@media screen and (max-width: 700px) {
    header {
        padding: 15px;
    }

    header p {
        padding: 3px 0;
    }

	#legend {
		grid-template-columns: 1fr .5fr;
		height: 40vh;
		padding: 0.3rem;
	}

	#legend span {
        height: min-content;
	}

	#legend p {
		display: none;
	}

    #legend hr {
        padding: 8px;
    }

    #banner {
        padding: 5px 0px;
    }
}
